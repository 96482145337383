/**
 * Inspired by https://github.com/SGrondin/bottleneck
 */
import { useEffect, useRef, useState } from "react";

export type Callback = () => void;

export interface UseIntervalProps {
  /** Could be a useCallback */
  callback: Callback
  /** 
   * Time interval to run callback (ms)   
   * Default: 0
   */
  interval: number,
  /**
   * Default: 10
   */
  maxOccurrence?: number,
  /** Cancel the setInterval */
  cancel?: boolean,
}

export const useInterval = ({
  interval = 0,
  maxOccurrence,
  cancel,
  callback,
}: UseIntervalProps) => {
  const callbackRef = useRef<Callback>()
  const [occurrence, setOccurrence] = useState<number>(0)
  const [ intervalId, setIntervalId]= useState<NodeJS.Timeout>()

  useEffect(() => {
    if (!callbackRef.current && !cancel) {
      setOccurrence(occurrence+1)
      callback()
    }
    callbackRef.current = callback
  }, [callback, cancel])

  useEffect(() => {
    if (interval > 0 && !cancel ) {
      const id = setInterval(() => {
        // Only functional update works
        // 🙏 https://eight-bites.blog/en/2021/05/setinterval-setstate/
        setOccurrence( prev => prev + 1)
        callbackRef?.current?.()
      }, interval)
      setIntervalId(id)
      Boolean(occurrence > Number(maxOccurrence)) && clearInterval(id)

      return () => clearInterval(id)
    }
  }, [occurrence, interval, cancel ])

  return {
    occurrence,
    intervalId
  }
}

export default useInterval
