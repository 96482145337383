import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent, useContext, useState } from "react";
import Context from "../../context/Context";
import useEventDetails from "../../hooks/useEventDetails";
import useInterval from "../../hooks/useInterval";
import { getPlaylist } from "../../services";
import { MultiDayAgendaProps } from "../interfaces";
import MultiDayTab from "../multiDayTab";
import AgendaDate from "./agendaDate";
import { TButton, ToggleAgenda } from "./toggleAgenda";

const emptySessions = ["networking", "lunch"];
const MultiDayAgenda: FunctionComponent<MultiDayAgendaProps> = (props) => {
  const [toggleState, setToggleState] = useState<boolean>(false);
  const data = useContext(Context);
  const { speakers, sessions: initialSessions } = data;
  const { agenda, usDateToMachineDate } = useEventDetails();
  const activeTab = props.activeTab ?? 0;
  const [sessions, updateSessions] = useState(() => {
    // Mapping speaker attrs to the speakers in sessions. Done here so only
    // need to iterate through once
    initialSessions.forEach((session) => {
      {
        session.speakers &&
          session.speakers.forEach((sessionSpeaker) => {
            const speaker = speakers.filter(
              ({ name }) => name === sessionSpeaker.name
            )[0];
            if (!speaker) return;
            sessionSpeaker["title"] = speaker.title;
            sessionSpeaker["image"] = speaker.image;
          });
      }
    });

    return initialSessions;
  });

  let dates =
    sessions &&
    sessions
      .filter(({ type, date }) => !emptySessions.includes(type) && date)
      .map(({ date }) => date);
  dates = [...new Set(dates)];
  const formattedDates = dates.map((date) => usDateToMachineDate(date));
  const {
    site: {
      siteMetadata: {
        videoPlayerSetting: { liveSessionPlaylistId },
      },
    },
  } = useStaticQuery(graphql`
    query GetPlaylistID {
      site {
        siteMetadata {
          videoPlayerSetting {
            liveSessionPlaylistId
          }
        }
      }
    }
  `);

  const retryPlaylist = () => {
    getPlaylist(liveSessionPlaylistId).then(({ data: playlist }) => {
      const newSessions = [...sessions];
      const now = Date.now();

      playlist.forEach((video) => {
        const session = newSessions.filter(
          (session) => session?.tile?.videoId === video.id
        )[0];
        if (!session) return;

        // Set session active if video has no schedule or start time
        session.active =
          video.schedule && video.schedule.starts_at
            ? now > new Date(video.schedule.starts_at).getTime()
            : true;
        const sessionIndex = newSessions.indexOf(session);
        newSessions[sessionIndex] = session;
      });
      updateSessions(newSessions);
    });
  };

  useInterval({
    cancel: !Boolean(sessions),
    interval: 2000,
    callback: retryPlaylist,
  });

  return (
    <>
      {!agenda && (
        <ToggleAgenda>
          <TButton state={toggleState} setState={setToggleState} />
        </ToggleAgenda>
      )}
      {(!agenda && !toggleState) || (
        <MultiDayTab
          dates={formattedDates}
          activeTab={activeTab}
          title="Agenda"
          className="agenda"
          singleDayMode={true}
        >
          {dates.map((agendaDate, index) => {
            let dateSessions = sessions.filter(
              ({ date }) => date === agendaDate
            );
            return (
              <AgendaDate
                active={true} // Will be overwritten by MultiDayTab
                key={index}
                dayNum={index + 1}
                sessions={dateSessions}
                singleDayMode={true}
              />
            );
          })}
        </MultiDayTab>
      )}
    </>
  );
};

export default MultiDayAgenda;
