import React from "react"

export default function TabBar({ dates, activeTab, updateActiveTab }) {
  const renderDate = (date: Date): string => {
    return date.toLocaleString('en-US', { month: "long", day: "numeric", timeZone: 'America/New_York' });
  }
  return(
    <div className="tabBar">
      <div className={`header ${(activeTab===0) ? "active" : ""}`} onClick={() => updateActiveTab(0)}>
        <div className="monthday">All Days</div>
        <div className="barActive"></div>
      </div>
      {dates?.map((currentDate, index) => {
        const dayNum = index + 1;
        return (<React.Fragment key={dayNum} >
          <div className={`header ${(activeTab === dayNum) ? "active" : ""} item${dayNum}`} onClick={() => updateActiveTab(dayNum)}>
            <div className="monthday">Day {dayNum}: {renderDate(currentDate)}</div>
            <div className="barActive"></div>
          </div>
        </React.Fragment>)
      })}
    </div>
  )
}
