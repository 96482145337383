import React, { FunctionComponent, useState, useEffect } from "react";
import TabBar from './tabBar'

interface MultiDayTabProps {
  dates: Date[];
  children: any[];
  activeTab: number;
  title?: string;
  darkMode?: Boolean;
  className?: string;
  singleDayMode?: boolean;
}

export const MultiDayTab:FunctionComponent<MultiDayTabProps> = ({
  dates,
  activeTab: inputActiveTab = 0,
  children,
  title,
  darkMode = false,
  className,
  singleDayMode,
}) => {
  const [activeTab, setActiveTab] = useState(inputActiveTab);

  const updateActiveTab = (tabNum: number) => {
    setActiveTab(tabNum);
  }

  const renderTitle = () => {
    if (!!title) {
      return <h1 className="titlehead">{title}</h1>
    } else {
      return null;
    }
  }

  if (darkMode) {
    className = className + " dark";
  }

  useEffect(() => {
    // Getting the date in eastern time. Then representing it as a string to we
    // can get the dates of midnight for eastern time, and compare the current time
    // to that.
    dates.forEach((date, index) => {
      const easternDateStr = date.toLocaleDateString('en-US', { timeZone: 'America/New_York', year: 'numeric', month: 'numeric', day: 'numeric' });
      let [ month, day, year ] = easternDateStr.split("/");
      const dateStr:string = year + '-' +
                            ('0' + month).slice(-2) + '-' +
                            ('0' + day).slice(-2);

      const sngBeginningOfDay = new Date(`${dateStr}T00:00:00-0400`);
      const sngEndOfDay = new Date(`${dateStr}T23:59:59-0400`);
      const now = new Date();

      if (!singleDayMode && (sngBeginningOfDay.getTime() < now.getTime() && now.getTime() < sngEndOfDay.getTime())) {
        setActiveTab(index + 1);
      }
    });
  }, []);

  return (
    <div className={"multiDayTab " + className}>
      {renderTitle()}
      <section className="tabs">
        {!singleDayMode && <TabBar
          dates={dates}
          activeTab={activeTab}
          updateActiveTab={updateActiveTab}
        /> } 
        {children}
      </section>
    </div>
  );
}

export default MultiDayTab;
