import React from "react";
import MultiDaySession from "./multiDaySesssion";

export default function AgendaDate({
  active,
  dayNum,
  sessions,
  singleDayMode,
}) {
  return (
    <React.Fragment key={dayNum}>
      {active && sessions && (
        <div className="sessions tabContent">
          {!singleDayMode && (
            <div className="fulldate">
              Day {dayNum}: {dayNum == 1 && "Tuesday, June 15"}{" "}
              {dayNum == 2 && "Wednesday, June 16"}
            </div>
          )}
          {sessions.map((session, key) => {
            if (session.title) {
              return <MultiDaySession key={key} {...session} />;
            } else {
              return;
            }
          })}
          <div className="pending">
            <br />
            <br />* Pending Agency Approval
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
