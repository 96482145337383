import React, { FunctionComponent, ReactComponentElement } from "react";
import DownCaret from "../../../static/images/agenda/down_caret.svg";
import UpCaret from "../../../static/images/agenda/up_caret.svg";
import { TButtonProps, ToggleProps } from "../interfaces";

const TButton: FunctionComponent<TButtonProps> = ({ state, setState }) => (
  <a className="tButton" onClick={() => setState(!state)}>
    <div className="blockleft">Agenda</div>
    {state && (
      <div className="caretblock">
        <img src={UpCaret} width="14px" />
      </div>
    )}
    {!state && (
      <div className="caretblock">
        <img src={DownCaret} width="14px" />
      </div>
    )}
  </a>
);

const ToggleAgenda: FunctionComponent<ToggleProps> = (props: any) => {
  return (
    <div className="toggleAgenda">
      <div className="container">
        <div className="followtxt">Follow along with today's Agenda.</div>
        {props.children}
      </div>
    </div>
  );
};

export { TButton, ToggleAgenda };
