import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent, useEffect } from "react";
import Layout from "../../components/layout";
import MultiDayAgenda from "../../components/multidayagenda";
import SEO from "../../components/seo";
import { trackPageview } from "../../services";

const Agenda: FunctionComponent = ({ day = "12/08/2022" }) => {
  const { allSpeakersJson, allSessionsJson, site } = useStaticQuery(graphql`
    query AgendaQuery {
      allSpeakersJson {
        agendaSpeakers: nodes {
          image
          name
          title
        }
      }
      allSessionsJson {
        agendaSessions: nodes {
          date
          startTime
          endTime
          title
          speakers {
            isModerator
            name
          }
          tile {
            buzzWord
            image
            slug
            videoId
          }
        }
      }
      site {
        siteMetadata {
          singlePage
          defaultTabs {
            defaultAgendaTab: agenda
          }
        }
      }
    }
  `);
  const dayNumber = (day.match(/\d+/) || [])[0];
  const { agendaSpeakers } = allSpeakersJson;
  const { agendaSessions } = allSessionsJson;
  const { defaultAgendaTab, singlePage } = site.siteMetadata.defaultTabs;

  useEffect(() => {
    trackPageview("/agenda");
  }, []);

  return (
    <Layout menu={singlePage ? "single" : ""}>
      <MultiDayAgenda
        activeTab={dayNumber >= 0 ? dayNumber : defaultAgendaTab}
      />
    </Layout>
  );
};

export default Agenda;

export const Head = () => {
  return (
    <SEO title="Agenda">
      <script type="application/ld+json">{JSON.stringify({})}</script>
    </SEO>
  );
};
