import { withPrefix } from "gatsby";
import React, { useState } from "react";
import { AuthLink } from "../links";

export default function MultiDaySession(props) {
  const [active, setActive] = useState(props.active);

  return (
    <div className="session">
      <div className="time">
        {props.breakout && (
          <span className="breakIt">
            Breakout {props.breakout}
            <br></br>
          </span>
        )}
        {props.startTime} - {props.endTime}
      </div>
      <div className="divider">
        <div className="bulletbox">
          <div className="squarebullet"></div>
        </div>

        {props.title === "Closing Remarks" || (
          <div className="linedivider"></div>
        )}
      </div>
      <div className="groupRight">
        <div className="sessiontitle">
          {props.title}
          <br />
          {props.title2}
        </div>
        <div className="speakerOuterWrap">
          {props.speakers &&
            props.speakers.map((speaker, key) => (
              <div className="speaker" key={key}>
                <div className="speakerInnerWrap">
                  <div>
                    {speaker.image && (
                      <img src={withPrefix(speaker.image)} alt={speaker.name} />
                    )}
                  </div>
                  <div>
                    {speaker.isModerator && (
                      <span className="mod">
                        Moderator: <br />
                      </span>
                    )}
                    <span className="name">{speaker.name}</span>
                    <br />
                    <span className="speakertitle">{speaker.title}</span>
                  </div>
                </div>
              </div>
            ))}
          {props.sponsor && (
            <div className="speaker">
              <div className="speakerInnerWrap">
                <div>
                  <img
                    src={withPrefix(props.sponsor)}
                    className="agendaSponsor"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`watchNow ${props.active ? "active" : ""}`}>
          {!props.sponsor && props.tile && (
            <AuthLink to={`/live/${props?.tile?.slug || "#"}`}>
              WATCH NOW
            </AuthLink>
          )}
        </div>
      </div>
    </div>
  );
}
